import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";

export default function WalletsDetails(props) {
  console.log(props);
  const { t } = useTranslation();
  const handleClose = () => {
    props.func(false);
  };

  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("US.dialogs.wallets.title")}
        </DialogTitle>
        <DialogContent>
          <List component="div" disablePadding>
            {!!props.wallets.length &&
              props.wallets.map((wallet) => (
                <ListItem button>
                  <ListItemText primary={wallet.wallet.name} />
                  {/* <ListItemIcon
                  >
                    <DeleteIcon />
                  </ListItemIcon> */}
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            <Button variant="contained" color="primary">
              {t("US.dialogs.wallets.bt1")}
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
