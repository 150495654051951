import styled from "styled-components";

export const Page = styled.div`
  height: 100%;
  transform: ${(props) => props.translate};
  transition: 0.2s ease-in-out;
  display: block;
  ._2fq0t {
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-color: #f7f7f7;
    overflow: hidden;
    position: absolute;
    ._1FroB {
      display: flex;
      flex: none;
      box-sizing: border-box;
      color: #fff;
      height: 108px;
      flex-direction: column;
      justify-content: flex-end;
      background-color: #00bfa5;
      padding-right: 20px;
      padding-left: 25px;
      .Ghmsz {
        align-items: center;
        display: flex;
        flex: none;
        width: 100%;
        text-align: initial;
        background-color: transparent;
        padding: 0;
        height: 59px;
        .SFEHG {
          flex: none;
          width: 54px;
          ._1aTxu {
            border: 0;
            margin: 0;
            background: 0 0;
            outline: 0;
            cursor: pointer;
            font-family: inherit;
            flex: none;
            margin-top: -2px;
            cursor: pointer;
            vertical-align: top;
          }
        }
        ._1xGbt {
          white-space: nowrap;
          -webkit-flex-grow: 1;
          flex-grow: 1;
          font-weight: 500;
          font-size: 19px;
          line-height: normal;
          text-overflow: ellipsis;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          overflow: hidden;
        }
      }
    }
    ._2sNbV {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      opacity: 1;
      overflow-y: auto;
      z-index: 2;
      overflow-x: hidden;
      ._12fSF {
        display: -webkit-flex;
        display: flex;
        -webkit-flex: none;
        flex: none;
        -webkit-justify-content: center;
        justify-content: center;
        margin-bottom: 28px;
        margin-top: 28px;
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation: XVXvJ 750ms cubic-bezier(0.1, 0.82, 0.25, 1);
        animation: XVXvJ 750ms cubic-bezier(0.1, 0.82, 0.25, 1);
        ._1wCju {
          position: relative;
          ._2UkYn {
            height: 200px;
            width: 200px;
            overflow: hidden;
            margin-right: auto;
            margin-left: auto;
            position: relative;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 50%;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            ._1WNtc {
              outline: none;
              position: absolute;
              top: 0;
              height: 100%;
              width: 100%;
              z-index: 1;
              right: 0;
              left: 0;
              .IuYNx {
                position: absolute;
                overflow: hidden;
                top: 0;
                height: 100%;
                width: 100%;
                left: 0;
                text-align: center;
                right: 0;
                ._3ZW2E {
                  position: absolute;
                  top: 0;
                  z-index: 1;
                  left: 0;
                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
      ._1CRb5 {
        flex: none;
        overflow: hidden;
        margin-bottom: 10px;
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
        ._2VQzd {
          padding: 14px 30px;
          padding: 0;
          margin-bottom: 14px;
          .LlZXr {
            display: flex;
            align-items: center;
            ._3e7ko {
              flex: 1 1 auto;
              overflow: hidden;
              ._1sYdX {
                color: #128c7e;
                font-weight: 300;
                line-height: normal;
              }
            }
            ._1AUd- {
              position: relative;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .ogWqZ {
          position: relative;
          word-break: break-word;
          ._2rXhY {
            font-size: 16px;
            position: absolute;
            color: rgba(0, 0, 0, 0.5);
            transition: all 0.3s cubic-bezier(0.1, 0.82, 0.25, 1);
            padding-top: 8px;
            -webkit-transform: translateY(24px);
            transform: translateY(24px);
            line-height: 20px;
            width: 100%;
          }
          .CrwPM {
            font-size: 12px;
            line-height: 16px;
            -webkit-transform: translateY(0);
            transform: translateY(0);
          }
          ._2OIDe {
            position: relative;
            height: 0;
          }
          ._1DTd4 {
            display: flex;
            -webkit-align-items: flex-start;
            align-items: flex-start;
            border-bottom: 1px solid rgba(38, 50, 56, 0.18);
            border-bottom: 2px solid #00bfa5;
            ._3F6QL {
              position: relative;
              flex: 1;
              display: flex;
              overflow: hidden;
              padding-right: 0;
              cursor: text;
              ._39lwd {
                left: 2px;
                visibility: hidden;
              }
              ._2S1VP {
                position: relative;
                white-space: pre-wrap;
                word-wrap: break-word;
                font-size: 16px;
                line-height: 20px;
                min-height: 20px;
                width: 100%;
                -webkit-user-select: text;
                -moz-user-select: text;
                -ms-user-select: text;
                user-select: text;
                padding: 0;
                min-width: 5px;
                margin: 8px 0;
              }
            }
            ._2YmC2 {
              position: relative;
              flex: none;
              margin-top: 1px;
              ._3jFFV {
                position: relative;
                display: inline-block;
                width: 24px;
                padding-bottom: 20px;
                margin-right: 4px;
                margin-left: 4px;
                ._3cyFx {
                  position: absolute;
                  top: 4px;
                  right: 0;
                  left: 0;
                }
              }
            }
          }
        }
      }
      ._idKB {
        margin: 4px 20px 28px 30px;
        -webkit-animation: _3Gjbg 1s cubic-bezier(0.1, 0.82, 0.25, 1);
        animation: _3Gjbg 1s cubic-bezier(0.1, 0.82, 0.25, 1);
        .Cpiae {
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
      ._34vig {
        -webkit-animation: _1hBRh 1s cubic-bezier(0.1, 0.82, 0.25, 1);
        animation: _1hBRh 1s cubic-bezier(0.1, 0.82, 0.25, 1);
      }
      ._2phEY {
        padding: 14px 30px 10px;
      }
    }
  }

  .copyable-area {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  @keyframes XVXvJ {
    0%,
    20% {
      -webkit-transform: scale(0, 0);
      transform: scale(0, 0);
    }
    100% {
      -webkit-transform: scale 1, 1;
      transform: scale 1, 1;
    }
  }
  @keyframes _1hBRh {
    0%,
    30% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes _3Gjbg {
    0%,
    30% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
