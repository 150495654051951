import styled from "styled-components";

export const Page = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #e5ddd5;
  transform-origin: left top;
  .YUoyu {
    position: absolute;
    right: 0;
    left: 0;
    height: 100%;
    top: 0;
    width: 100%;
    opacity: 0.06;
    background-repeat: repeat repeat;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 2dppx) {
    .YUoyu {
      background-size: 366.5px 666px;
    }
  }
`;
